import React from 'react';

import Header from '../component/Header';
import Login from '../component/Login';

const Landing = () => {
  return (
    <>
      <Header />
      <div className='w3-padding'>
        <Login />
      </div>
    </>
  );
};

Landing.propTypes = {};

export default Landing;
