import { proxy } from 'valtio';

const ALERT_DURATION_MS = 3500;

const state = proxy({
  msgs: [],
  logErr: (message) => {
    state.msgs.push({ text: message, type: 'error' });
    setTimeout(
      () => state.msgs.shift(),
      ALERT_DURATION_MS + state.msgs.length * 100
    );
  },
  logInfo: (message) => {
    state.msgs.push({ text: message, type: 'info' });
    setTimeout(
      () => state.msgs.shift(),
      ALERT_DURATION_MS + state.msgs.length * 100
    );
  },
  logWarn: (message) => {
    state.msgs.push({ text: message, type: 'warn' });
    setTimeout(
      () => state.msgs.shift(),
      ALERT_DURATION_MS + state.msgs.length * 100
    );
  },
  logSuccess: (message) => {
    state.msgs.push({ text: message, type: 'success' });
    setTimeout(
      () => state.msgs.shift(),
      ALERT_DURATION_MS + state.msgs.length * 100
    );
  },
});

export default state;
