import { proxy } from 'valtio';
import auth from './auth';
import expense from './expense';
import user from './user';
import admin from './admin';
import alert from './alert';
import recurrent from './recurrent';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const state = proxy({
  auth,
  expense,
  user,
  admin,
  alert,
  rec: recurrent,
  loading: false,
  error: null,
  init: async () => {
    try {
      state.loading = true;
      // add any first loading required here
      if (auth.isAuth) {
        await admin.listen();
        await user.fetch();
        let profileUpdate = null;
        if (!user.profile.timezone) {
          const timezone = dayjs.tz.guess();
          profileUpdate = { timezone };
        }
        if (!user.profile.locale) {
          if (!profileUpdate) {
            profileUpdate = {};
          }
          profileUpdate.locale = navigator.language;
        }
        if (profileUpdate) {
          user.save(profileUpdate);
        }
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    } finally {
      state.loading = false;
    }
  },
  cleanup: async () => {
    try {
      state.loading = true;
      // add any first loading required here
      if (auth.isAuth) {
        await admin.cleanup();
        await expense.cleanup();
        await expense.cleanup();
        await recurrent.cleanup();
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    } finally {
      state.loading = false;
    }
  },
});

export default state;
