import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';
import dayjs from 'dayjs';

import store from '../store';

function getSelectedItems(items) {
  return Object.values(items).reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
}

const XpenseList2 = (props) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [toggleAll, setToggleAll] = useState(false);
  const [loading, setLoading] = useState(false);

  async function onDelete() {
    try {
      const userChoice = window.confirm('Are you sure?');
      if (userChoice) {
        setLoading(true);
        const idsToDelete = Object.keys(selectedItems).filter(
          (id) => selectedItems[id]
        );
        await store.expense.deleteAll(idsToDelete, props.monthKey);
        store.alert.logSuccess('Deletion complete');
        setSelectedItems({});
        setToggleAll(false);
      }
    } catch (error) {
      console.error(error.message);
      store.alert.logErr('Something went wrong. Please check and try again.');
    } finally {
      setLoading(false);
    }
  }

  function onSelectItem(id) {
    setSelectedItems({ ...selectedItems, [id]: !selectedItems[id] });
    const nbSelectedItem = getSelectedItems(selectedItems);
    let newToggleAll = nbSelectedItem !== 1 || !selectedItems[id];
    setToggleAll(newToggleAll);
  }
  function onToggleAll() {
    const newSelectedItems = {};
    setToggleAll(!toggleAll);
    setSelectedItems(newSelectedItems);
  }
  return (
    <div>
      <ul className='w3-ul '>
        {toggleAll && (
          <li
            className='w3-bar w3-gentlefi-l5 w3-border'
            style={{ paddingTop: 0, paddingBottom: 0 }}
          >
            <div className='w3-bar-item'>
              <input
                type='checkbox'
                onChange={onToggleAll}
                checked={toggleAll}
              />
            </div>
            <div className='w3-bar-item w3-right'>
              <button
                className={
                  'w3-button w3-round w3-small w3-red ' +
                  (loading ? 'w3-disabled' : '')
                }
                onClick={onDelete}
              >
                Delete Selection
              </button>
            </div>
          </li>
        )}
        {props.expenses.map((xp) => (
          <ListItem
            key={xp.id}
            xp={xp}
            onSelectItem={onSelectItem}
            onLabelClick={props.onLabelClick}
            selected={selectedItems[xp.id] || false}
          />
        ))}
      </ul>
    </div>
  );
};

const ListItem = (props) => {
  const { currencyFormatter } = useSnapshot(store.user);
  const xpDate = dayjs(props.xp.xpdate, 'YYYYMMDD');

  function onLabelClick(event) {
    if (props.onLabelClick) {
      const labelFilter = event.target.getAttribute('name');
      props.onLabelClick(labelFilter);
    }
  }

  const labelList = Object.keys(props.xp.labels).map((l) => (
    <span
      className='w3-tag w3-round w3-light-grey w3-margin-right clickable'
      onClick={onLabelClick}
      key={l}
      name={l}
    >
      {l}
    </span>
  ));
  function onSelect() {
    props.onSelectItem(props.xp.id);
  }

  return (
    <li className='w3-bar'>
      <div className='w3-bar-item'>
        <input
          className=''
          type='checkbox'
          onChange={onSelect}
          checked={props.selected}
        />
      </div>
      <div className='w3-bar-item'>{xpDate.format('MMM DD')}</div>
      <div className='w3-bar-item'>
        <b className={props.xp.isExpense ? '' : 'w3-text-green'}>
          {currencyFormatter.format(props.xp.amount)}
        </b>
      </div>
      <div
        className='w3-bar-item'
        title={props.xp.original}
        style={{ paddingLeft: 10 }}
      >
        {labelList}
      </div>
    </li>
  );
};

XpenseList2.propTypes = {
  expenses: PropTypes.array, // list of expenses
  onLabelClick: PropTypes.func, // what happens when a label is clicked on
};

export default XpenseList2;
