import { proxy } from 'valtio';
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';

import { auth } from './firebase';

const state = proxy({
  loading: false,
  error: '',
  isAuth: false,
  credentials: null,
  login: async (username, pwd) => {
    try {
      state.loading = true;
      await signInWithEmailAndPassword(auth, username, pwd);
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
      throw error;
    }
  },
  logout: async () => {
    try {
      await signOut(auth);
      state.isAuth = false;
      state.credentials = null;
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    }
  },
  changePwd: async (curPwd, newPwd) => {
    try {
      const credentials = EmailAuthProvider.credential(
        auth.currentUser.email,
        curPwd
      );
      await reauthenticateWithCredential(auth.currentUser, credentials);
      await updatePassword(auth.currentUser, newPwd);
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
      throw error;
    }
  },
});

onAuthStateChanged(auth, (user) => {
  if (user) {
    state.isAuth = true;
    state.credentials = JSON.parse(JSON.stringify(user));
  }
  state.loading = false;
});

export default state;
