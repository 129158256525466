import dayjs from 'dayjs';

const shortMonthToNumber = {
  jan: '01',
  feb: '02',
  mar: '03',
  apr: '04',
  may: '05',
  jun: '06',
  jul: '07',
  aug: '08',
  sep: '09',
  oct: '10',
  nov: '11',
  dec: '12',
};

// those regexes allow for spaces on each side, and
// also for the content to be at the beginning or at the end
// of the string

// ISO dates allow for YYYY-MM-DD
export const xpDateRegex =
  /(?:\s|^)[0-9]{4}-[0-1]{1}[0-9]{1}-[0-3]{1}[0-9]{1}(?:\s|$)/i;

// abbreviated dates allow for Jul02, Jul 02, Jul 2, Jul2
export const shortDateRegex =
  /(?:\s|^)(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)\s{0,1}[0-9]{1,2}(?:\s|$)/i;

// amounts can be 50.34, 50, +50.34 or +50
export const xpAmountRegex = /(?:\s|^)[+-]{0,1}\d+\.{0,1}\d*(?:\s|$)/i;

// recurrent are rec1d, rec2w, re3m, rec4y,
export const recurrentRegex = /(?:\s|^)rec[0-9]+[dwmy](?:\s|$)/i;

export function getRecurrentInfo(rec) {
  // given a recurrent syntaxt, returns the info
  // rec has this format: rec[0-9]+[dwmy]
  // Ex: rec2w means happens every 2 weeks
  // throw if the parameter is ill formatted
  // return an object {interval:[dwmy], frequency:number}
  if (recurrentRegex.test(rec)) {
    const interval = rec[rec.length - 1].toLowerCase();
    const frequency = parseInt(rec.substring(3, rec.length - 1));
    if (isNaN(frequency)) {
      throw new Error('impossible to understand frequency for ' + rec);
    }
    if (frequency === 0) {
      throw new Error('invalid 0 frequency from rec string ' + rec);
    }
    return { interval, frequency };
  } else {
    throw new Error('Ill formatted recurrent string ' + rec);
  }
}

function getRecurrentStringFromOriginal(originalInput) {
  // Extract any recurrent information contained in the input
  // originalInput is the original user input
  // return the recurrent string if any, otherwise an empty string
  let recurrent = '';
  let recString = originalInput.match(recurrentRegex);
  if (recString) {
    // the regex includes spaces so we need to trim it
    recurrent = recString[0].trim();
  }

  return recurrent;
}

function getAmountFromOriginal(originalInput) {
  // Extract the amount out of the original user input
  // originalInput is the original user input
  // return a number as the amount, 0 if none has been found, and the
  //    amount string as found in the original input if any
  let amountCandidate = 0;
  let amountFound = null;
  let xpenseAmount = originalInput.match(xpAmountRegex);
  if (xpenseAmount) {
    // the regex includes spaces so we need to trim it
    amountFound = xpenseAmount[0].trim();
    xpenseAmount = xpenseAmount[0].trim();
    amountCandidate = parseFloat(xpenseAmount);
    if (amountCandidate > 0 && xpenseAmount[0] !== '+') {
      // here we don't know if it's actually a revenue or if
      // the user just didn't specify the - sign as it is
      // allowed to do so
      amountCandidate = amountCandidate * -1;
    }
  }

  return { amount: amountCandidate, amountFound };
}

export function extractXpDate(original, timezone = null) {
  // extract a potential date from a user input
  // return null if no date is present, otherwise the date as YYYY-MM-DD
  let dateFormatted = null;
  let dateFound = null;

  // Testing for ISO format
  let xpenseDate = original.match(xpDateRegex);
  if (xpenseDate) {
    dateFound = xpenseDate[0].trim();
    dateFormatted = xpenseDate[0].trim();
  } else {
    // testing for Short format dates!
    xpenseDate = original.match(shortDateRegex);
    if (xpenseDate) {
      dateFound = xpenseDate[0].trim();
      let shortDate = xpenseDate[0].trim().toLowerCase();
      // removing the potential space
      shortDate = shortDate.replace(' ', '');

      // extracting month as three letters
      const shortMonth = shortMonthToNumber[shortDate.substring(0, 3)];
      if (!shortMonth) {
        throw new Error('cannot deduce month ' + shortDate);
      }

      // extracting the day. If the user typed 1 instead of 01, we
      // complete with zeros
      let shortDay = shortDate.substring(3);
      if (shortDay === '0') {
        throw new Error('the day cannot be 0 ' + shortDate);
      }
      if (shortDay.length === 1) {
        shortDay = '0' + shortDay;
      }

      let userYear = new Date().getFullYear();
      if (timezone) {
        userYear = dayjs().tz(timezone).format('YYYY');
      }
      dateFormatted = `${userYear}-${shortMonth}-${shortDay}`;
    }
  }

  return { dateFound, dateFormatted };
}

function getDateFromOriginal(originalInput, timezone = null) {
  // Extract the date out of the original user input
  // - originalInput is the original user input
  // return today's date as YYYY-MM-DD according to the provided
  // - timezone, and the extracted date's fingerprint YYYYMMDD as na INTEGER
  let todayForUser = dayjs();
  if (timezone) {
    todayForUser = dayjs().tz(timezone);
  }
  const todayForUserFormatted = todayForUser.format('YYYY-MM-DD');

  // if no date has been specified by the user, using current day
  const originalXpenseDateInfo = extractXpDate(originalInput, timezone);
  const xpenseDate =
    originalXpenseDateInfo.dateFormatted || todayForUserFormatted;

  return {
    today: todayForUserFormatted,
    dateFingerprint: parseInt(xpenseDate.replace(/-/g, '')),
    dateInOriginal: originalXpenseDateInfo.dateFound,
  };
}

function getLabelsFromOriginal(originalInput) {
  // Tokenize the original user input (forn ow, just a blanc space split) and extract all non number token
  // - originalInput is the original user input
  // return an object whose keys are the tokens and the value are always true
  const words = originalInput.split(/\s/);
  const res = {};
  for (let w of words) {
    if (w) {
      const lowerCase = w.toLowerCase();
      res[lowerCase] = true;
    }
  }
  return res;
}

export function generateExpense(original, uid) {
  // given a string input, generate an expense object carrying
  // all the relevant information
  // original is the user input
  // uid is the user id
  if (!original) {
    throw new Error('The expense original text cannot be empty');
  }

  original = original.trim();

  if (!original) {
    return null;
  }

  const newXpense = {};
  newXpense.original = original;
  newXpense.uid = uid;
  newXpense.isRec = false;

  //@TODO: add a regex on UTC+/-[0-12]

  // Looking for the date the expense was made
  const dateInfo = getDateFromOriginal(original);
  newXpense.created = dateInfo.today;
  newXpense.updated = dateInfo.today;
  newXpense.xpdate = dateInfo.dateFingerprint;

  // Looking for the amount of the expense
  const amountInfo = getAmountFromOriginal(original);
  newXpense.amount = amountInfo.amount;

  // Looking for a recurring information string, ex: rec1m, rec2w
  newXpense.recurrent = getRecurrentStringFromOriginal(original);

  // adding a filter criteria saying if the expense is an expense
  // or a revenue line
  if (newXpense.amount >= 0) {
    newXpense.isExpense = false;
  } else {
    newXpense.isExpense = true;
  }

  // extracting labels from a string without the other info
  let extractLabelFrom = original
    .replace(dateInfo.dateInOriginal, '')
    .replace(amountInfo.amountFound, '');
  if (newXpense.recurrent) {
    extractLabelFrom = extractLabelFrom.replace(newXpense.recurrent, '');
  }
  newXpense.labels = getLabelsFromOriginal(extractLabelFrom);

  return newXpense;
}
