import React from 'react';
import { useSnapshot } from 'valtio';

import store from '../store';

const basicPanelCss =
  'w3-panel w3-padding w3-leftbar w3-display-container w3-bar ';
const errorCss = basicPanelCss + 'w3-pale-red w3-border-red';
const warnCss = basicPanelCss + 'w3-pale-yellow w3-border-yellow';
const infoCss = basicPanelCss + 'w3-pale-blue w3-border-blue';
const successCss = basicPanelCss + 'w3-pale-green w3-border-green';

const CSS_TYPES = {
  error: errorCss,
  warn: warnCss,
  info: infoCss,
  success: successCss,
};

const MsgContainer = (props) => (
  <div className={props.css} style={{ width: '100%' }}>
    <span className='w3-bar-item w3-left'>{props.msg}</span>
  </div>
);

const AlertMessage = () => {
  const { msgs } = useSnapshot(store.alert);

  return (
    <div className='w3-display-topright' style={{ position: 'fixed', top: 0 }}>
      {msgs.map((msg, index) => (
        <MsgContainer
          key={'msg' + index}
          msg={msg.text}
          css={CSS_TYPES[msg.type]}
        />
      ))}
    </div>
  );
};

AlertMessage.propTypes = {};

export default AlertMessage;
