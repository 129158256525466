import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useSnapshot } from 'valtio';

import Home from './page/Home';
import Landing from './page/Landing';
import Account from './page/Account';
import Recurrent from './page/Recurrent';
import Playground from './page/Playground';
import PrivacyPolicy from './page/PrivacyPolicy';
import Tos from './page/Tos';
import Tutorial from './page/Tutorial';
import Onboarding from './page/Onboarding';

import AlertMessage from './component/AlertMessage';

import { ROUTES } from './constants';

import store from './store';

function App() {
  const { loading } = useSnapshot(store);
  const { credentials } = useSnapshot(store.auth);

  useEffect(() => {
    if (credentials) {
      store.init();
    } else {
      store.cleanup();
    }

    return () => {
      store.cleanup();
    };
  }, [credentials]);

  return (
    <>
      <AlertMessage />
      {loading && 'Loading...'}
      {!loading && (
        <Routes>
          <Route
            path={ROUTES.ROOT}
            element={credentials ? <Home /> : <Landing />}
          />
          <Route
            path={ROUTES.HOME}
            element={credentials ? <Home /> : <Landing />}
          />
          <Route
            path={ROUTES.ACCOUNT}
            element={credentials ? <Account /> : <Landing />}
          />
          <Route
            path={ROUTES.RECURRENT}
            element={credentials ? <Recurrent /> : <Landing />}
          />
          <Route
            path={ROUTES.ONBOARDING}
            element={credentials ? <Onboarding /> : <Landing />}
          />
          <Route path={ROUTES.TUTORIAL} element={<Tutorial />} />
          <Route path={ROUTES.PLAYGROUND} element={<Playground />} />
          <Route path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
          <Route path={ROUTES.TOS} element={<Tos />} />
        </Routes>
      )}
    </>
  );
}

export default App;
