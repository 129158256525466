import React, { useState } from 'react';
import PropTypes from 'prop-types';
import store from '../store';

const AddExpense = (props) => {
  const [xpenseInput, setXpenseInput] = useState('');

  async function onSubmit(event) {
    try {
      event.stopPropagation();
      event.preventDefault();
      if (xpenseInput) {
        await store.expense.add([xpenseInput]);
        store.alert.logSuccess('Saved');
        setXpenseInput('');
        if (props.onSubmit) {
          props.onSubmit();
        }
      }
    } catch (error) {
      store.alert.logErr('Something went wrong. Please check and try again.');
    }
  }
  function onUserInput(event) {
    setXpenseInput(event.target.value);
  }
  function onCancel() {
    setXpenseInput('');
    if (props.onCancel) {
      props.onCancel();
    }
  }
  return (
    <form onSubmit={onSubmit} className='w3-bar'>
      <input
        className='w3-bar-item w3-input w3-border w3-round'
        tpye='text'
        placeholder='ex: 30 groceries 2023-06-01'
        onChange={onUserInput}
        value={xpenseInput}
      />
      <button
        className='w3-button w3-bar-item material-icons w3-green w3-round'
        style={{ marginLeft: 10 }}
        onClick={onSubmit}
      >
        check
      </button>
      <button
        className='w3-button w3-border w3-round w3-bar-item w3-underline'
        style={{ marginLeft: 10 }}
        onClick={props.onAddSeveral}
      >
        Add Several at Once
      </button>
      <button
        className='w3-button w3-bar-item w3-right w3-bar'
        style={{ paddingTop: 0, paddingBottom: 0 }}
        onClick={onCancel}
      >
        <i className='w3-bar-item material-icons' style={{ paddingRight: 0 }}>
          cancel
        </i>
        <span className='w3-bar-item' style={{ paddingLeft: 10 }}>
          Close
        </span>
      </button>
    </form>
  );
};

AddExpense.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddExpense;
