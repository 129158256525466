import React from 'react';

import Header from '../component/Header';

const Tos = () => {
  return (
    <>
      <Header />
      <div className='content w3-padding'>
        <div className='w3-panel w3-leftbar w3-gentlefi-l4 w3-border-gentlefi'>
          <h3>tl;dr - Read at least this part</h3>
          <ul>
            <li>
              This website is to assist you with your personal finances and does
              NOT provide any financial advice{' '}
            </li>
            <li>
              Before making any financial decision, do your own calculations
            </li>
            <li>We do our best, but there is no guarantee</li>
            <li>
              If there is any issue, we'll use the law of the province of
              Ontario in Canada
            </li>
          </ul>
        </div>
        <iframe
          title='Terms of Services for Gentle Finance'
          className='w3-block'
          style={{ height: '100vh' }}
          src='https://docs.google.com/document/d/e/2PACX-1vQsr9NzFgOp-cQQCy-EnfjFwVNGH4YVAnf56rflBgLtnZzDe9_Rcp4P9pNHFzyVFg/pub?embedded=true'
        ></iframe>
      </div>
    </>
  );
};

export default Tos;
