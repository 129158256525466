import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../component/Header';
import Step1 from '../component/tutorial/TutorialStep1';
import Step2 from '../component/tutorial/TutorialStep2';
import Step3 from '../component/tutorial/TutorialStep3';
import Step4 from '../component/tutorial/TutorialStep4';
import Step5 from '../component/tutorial/TutorialStep5';
import Step6 from '../component/tutorial/TutorialStep6';

import { ROUTES } from '../constants';

import store from '../store';

const Page = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function prev() {
    if (step > 1) {
      setStep(step - 1);
    }
  }
  async function next() {
    setStep(step + 1);
    if (step === 6) {
      setLoading(true);
      await store.user.save({ tutoDone: true });
      navigate(ROUTES.HOME);
    }
  }
  return (
    <>
      <Header />
      <div className='gf-page w3-padding'>
        <div
          className='w3-light-grey w3-round w3-section'
          style={{ height: 10 }}
        >
          <div
            className='w3-gentlefi-action w3-container w3-round w3-center'
            style={{ width: (step * 100) / 6 + '%', height: 10 }}
          ></div>
        </div>

        {loading && 'Redirecting...'}
        {step === 1 && <Step1 />}
        {step === 2 && <Step2 />}
        {step === 3 && <Step3 />}
        {step === 4 && <Step4 />}
        {step === 5 && <Step5 />}
        {step === 6 && <Step6 />}
        {!loading && (
          <div className='w3-bar w3-block w3-padding' style={{ marginTop: 10 }}>
            {step !== 1 && (
              <div
                onClick={prev}
                className='w3-bar-item w3-left w3-button w3-gentlefi-action w3-border w3-round'
              >
                Prev
              </div>
            )}
            <div
              onClick={next}
              className='w3-bar-item w3-right w3-button w3-gentlefi-action w3-border w3-round'
            >
              {step === 6 ? 'Done' : 'Next'}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

Page.propTypes = {};

export default Page;
