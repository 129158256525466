import { proxy } from 'valtio';
import { getDoc, doc, onSnapshot } from 'firebase/firestore';

import { db, COLLECTIONS, auth } from './firebase';

const state = proxy({
  loading: false,
  error: '',
  admin: {},
  unsub: null,
  listen: async () => {
    // listen to any changes in the user admin info
    if (auth?.currentUser?.uid) {
      const unsub = onSnapshot(
        doc(db, COLLECTIONS.ADMIN, auth?.currentUser?.uid),
        (doc) => {
          const docData = doc.data();
          state.admin.uid = doc.id;
          for (let field of Object.keys(docData)) {
            state.admin[field] = docData[field];
          }
        },
        (error) => {
          state.error = error.message;
        }
      );
      state.unsub = unsub;
    }
  },
  cleanup: () => {
    if (state.unsub) {
      state.unsub();
    }
  },
  fetch: async (force = false) => {
    // fetch the user admin info
    // force indicates if we should fetch the data even if we have already before
    try {
      if ((!state.admin.uid || force) && auth?.currentUser?.uid) {
        state.loading = true;
        const profileRef = doc(db, COLLECTIONS.ADMIN, auth?.currentUser?.uid);
        const docSnap = await getDoc(profileRef);

        state.admin = { ...docSnap.data(), uid: docSnap.id, id: docSnap.id };
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    } finally {
      state.loading = false;
    }
  },
});

export default state;
