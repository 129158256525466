import React from 'react';
import PropTypes from 'prop-types';

const ExpenseListToolbar = (props) => {
  return (
    <div className='w3-bar'>
      <button className='w3-bar-item w3-button' onClick={props.prevMonth}>
        <i className='material-icons'>keyboard_arrow_left</i>{' '}
      </button>
      <div className='w3-bar-item'>{props.curDate.format('MMMM YYYY')}</div>
      <button
        className='w3-bar-item w3-button'
        onClick={props.nextMonth}
        disabled={props.curDateIsThisMonth}
      >
        <i className='material-icons'>keyboard_arrow_right</i>{' '}
      </button>
      <button
        title='refresh list'
        className='w3-bar-item w3-button w3-right'
        onClick={props.refresh}
      >
        <i className='material-icons'>refresh</i>{' '}
      </button>
      {/* Add button for large screens */}
      <button
        title='add new expense'
        className='w3-hide-small w3-bar-item w3-button w3-right'
        onClick={props.toggleAdd}
      >
        <i className='material-icons'>add</i>{' '}
      </button>
      {/* Add button for small screens */}
      <button
        title='add new expense'
        className='w3-hide-large w3-hide-medium w3-bar-item w3-button w3-right'
        onClick={props.toggleAddSeveral}
      >
        <i className='material-icons'>add</i>{' '}
      </button>
      <button
        title='filter the list'
        className='w3-bar-item w3-button w3-right'
        onClick={props.toggleFilter}
      >
        <i className='material-icons'>filter_list</i>{' '}
      </button>
    </div>
  );
};

ExpenseListToolbar.propTypes = {
  prevMonth: PropTypes.func,
  nextMonth: PropTypes.func,
  curDateIsThisMonth: PropTypes.bool,
  refresh: PropTypes.func,
  toggleAdd: PropTypes.func,
  toggleFilter: PropTypes.func,
  curDate: PropTypes.object, // dayjs object
};

export default ExpenseListToolbar;
