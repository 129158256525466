import React, { useState } from 'react';

import Header from '../component/Header';

import FormToSms from '../component/FormToSms';

// import { generateExpense, getRecurrentInfo } from '../store/expense.utils';

// function createIntervalLabel(rec) {
//   let res = 'month';
//   if (rec.interval === 'd') {
//     res = 'day';
//   } else if (rec.interval === 'w') {
//     res = 'week';
//   } else if (rec.interval === 'y') {
//     res = 'year';
//   }
//   if (rec.frequency === 1) {
//     res = 'Every ' + res;
//   } else {
//     res = 'Every ' + rec.frequency + ' ' + res + 's';
//   }

//   return res;
// }

const TextContainer = (props) => {
  return (
    <div className='w3-panel w3-gentlefi-l5 w3-round w3-leftbar w3-border-gentlefi-l4'>
      {props.children}
    </div>
  );
};

const Tag = (props) => {
  return (
    <span
      className='w3-tag w3-round w3-gentlefi-action w3-text-white w3-small'
      style={{ marginRight: 5 }}
    >
      {props.children}
    </span>
  );
};

const Page = () => {
  // const [userInput, setUserInput] = useState('');
  const [smsSyntax, setSmsSyntax] = useState('');

  function onNewSMs(newSMs) {
    setSmsSyntax(newSMs);
  }

  // const [expense, setExpense] = useState(null);

  // let recLabel = 'Does not repeat';
  // if (expense?.recurrent) {
  //   const tmp = getRecurrentInfo(expense?.recurrent);
  //   recLabel = createIntervalLabel(tmp);
  // }

  // useEffect(() => {
  //   if (userInput) {
  //     const newExpense = generateExpense(userInput);
  //     setExpense(newExpense);
  //   } else {
  //     setExpense(null);
  //   }
  // }, [userInput]);

  // function onUserInput(event) {
  //   setUserInput(event.target.value);
  // }

  return (
    <>
      <Header />
      <div className='gf-page w3-padding'>
        <h1>Welcome to the Gentle Finance Playground!</h1>
        <p>
          Here you can figure out how to enter expenses. It gives you the syntax
          you would use via SMS/WhatsApp.
        </p>
        <p>
          <b>This is just practice. It will not be saved.</b>
        </p>
        Enter here the information you wish to save about your expense/revenue
        line:
        <FormToSms onFormChange={onNewSMs} />
        <p>
          Here is the corresponding SMS to send: <br />
        </p>
        <TextContainer className='w3-panel w3-light-grey w3-padding w3-round'>
          <div className='w3-bar'>
            <i className='w3-bar-item material-icons'>sms</i>
            <b className='w3-bar-item'>
              <code>
                {smsSyntax === ''
                  ? 'Enter some information to see the SMS'
                  : smsSyntax}
              </code>
            </b>
          </div>
          {/* <p>
            <input
              defaultValue={userInput}
              className='w3-input w3-border'
              type='text'
              placeholder='Ex: 50 groceries 2023-07-10'
              onChange={onUserInput}
            />
          </p> */}
          {/* {expense && (
            <ul>
              The resulting line of expense is: <br />
              <li className='w3-bar'>
                <i className='material-icons w3-bar-item'>attach_money</i>
                <span className='w3-bar-item'>${expense.amount}</span>
              </li>
              <li className='w3-bar'>
                <i className='material-icons w3-bar-item'>date_range</i>
                <span className='w3-bar-item'>
                  {dayjs(`${expense.xpdate}`, 'YYYYMMDD').format('DD MMM YYYY')}
                </span>
              </li>
              <li className='w3-bar'>
                <i className='material-icons w3-bar-item'>filter_list</i>
                <span className='w3-bar-item'>
                  {Object.keys(expense.labels).map((l) => (
                    <Tag key={l}>{l}</Tag>
                  ))}
                </span>
              </li>
              <li className='w3-bar'>
                <i className='material-icons w3-bar-item'>autorenew</i>
                <span className='w3-bar-item'>{recLabel}</span>
              </li>
            </ul>
          )}
          {!expense && (
            <p>
              Type anything in the input box above to see the result. Check on
              the rules below if you have any doubts.
            </p>
          )} */}
        </TextContainer>
        <h3>1. General Rules</h3>
        <TextContainer>
          <p>
            The simplest input is just a number. That's right, just typing{' '}
            <b>
              <code>30.12</code>
            </b>{' '}
            will create a new expense of $30.12 for the current day.
          </p>
          <p>
            Now, when you look back on your list of expense, chances are that
            you will want to remember what it was about. You can add whatever
            text you like to do so. For example <code>30.12 groceries</code>{' '}
            will tell your future self that you spent this amount on grocery
            shopping. You can add several words this way, which allow for a very
            granular tracking.
          </p>
          <p>
            There are a couple more rules. If you ever have any doubt, use the
            input above to try it out!
          </p>
        </TextContainer>
        <h3>2. Amount</h3>
        <TextContainer>
          <p>
            Write the amount spent as a number. If you type{' '}
            <b>
              {' '}
              <code> 50</code>
            </b>{' '}
            or{' '}
            <b>
              {' '}
              <code> -50</code>
            </b>
            , it will be considered an expense. You can also track your revenues
            by writing{' '}
            <b>
              {' '}
              <code> +50</code>
            </b>
            . <br />
            Do not use commas. Decimals should use the dot notation, ie:{' '}
            <code>
              <b>50.12</b>
            </code>
            .
          </p>
        </TextContainer>
        <h3>3. Date of spending</h3>
        <TextContainer>
          <p>
            If you don't specify a date, the date of the current day will be
            used. <br />
            <br /> If you want to track an expense made on a different day, you
            can specify the date by using the month's three letters abrevation
            and the date. <br />
            For example:{' '}
            <b>
              <code>jul23</code>
            </b>{' '}
            or{' '}
            <b>
              <code>jan31</code>
            </b>
            .
            <br />
            <br />
            Let us know if you want to add abreviations in another language as
            well!
            <br />
            <br />
            If the expense did not happen during the current year, all is not
            lost! You can then enter the date with the following format:{' '}
            <b>
              <code>YYYY-MM-DD</code>
            </b>
            . <br />
            For example: April 3 2023 will be written 2023-04-03.
          </p>
        </TextContainer>
        <h3>4. Recurring Amounts</h3>
        <TextContainer>
          <p>
            We often have regular revenues or expenses of the same amount.
            Internet bills and salaries are good examples. There is a way to
            tell Gentle Finance about them. The application will enter them for
            you on the date they occur. To any expense that you would usually
            write, add the label <code>rec</code>, followed by the desired
            frequency.
            <br />
            For example: 30 groceries rec1m will repeat every month on the date
            you submitted the input.
          </p>
          <p>Here are the available frequencies:</p>
          <ul>
            <li>
              <b>
                <code>d</code>
              </b>{' '}
              for a daily frequency. Ex: rec1d will repeat every day.
            </li>
            <li>
              <b>
                <code>w</code>
              </b>{' '}
              for a weekly frequency. Ex: rec2w will repeat every two weeks.
            </li>
            <li>
              <b>
                <code>m</code>
              </b>{' '}
              for a monthly frequency. Ex: rec3m will repeat every three months.
            </li>
            <li>
              <b>
                <code>y</code>
              </b>{' '}
              for a yearly frequency. Ex: rec1y will repeat every year.
            </li>
          </ul>
          <p>
            To summarize, the syntax is: rec, followed by the desired frequency
            as a number, followed by the interval as specified above.
          </p>
        </TextContainer>
        <h3>5. Labels</h3>
        <TextContainer>
          <p>
            Labels are any other words that you write. The labels are case
            insensitive and will appear in lower case in the interface.
          </p>
          <p>
            Labels can contain any character but no space. <br /> Ex: Grocery
            Brand will become two labels: <Tag>grocery</Tag>and <Tag>brand</Tag>
          </p>
          <p>
            Labels are probably the <b>most powerful feature </b> of Gentle
            Finance. Think of them as tags you can use to group and categorize
            your expenses. Unlike set categories, each expense can have several
            labels attached to them. <br />
            <br />
            For example, what if you spend $50 at a grocery store to buy a cake
            as a gift for someone? With set categories, you would have had to
            choose. With automatic extraction, it would have categorized it as a
            grocery bill. Thanks to labels, you can now keep track of both
            information. You can even add the name of who you bought this
            amazing cake for, and you'll see it in the web interface later as a
            reminder!
          </p>
          {/* <p>
            <span style={{ textDecoration: 'underline' }}>Caveat:</span> Since
            you are the one entering the labels by hand, it can seem annoying to
            remember which label you previously used. Was it{' '}
            <span className='w3-round w3-tag w3-gentlefi-action w3-text-white w3-small'>
              groceries
            </span>{' '}
            or{' '}
            <span className='w3-round w3-tag w3-gentlefi-action w3-text-white w3-small'>
              grocery
            </span>
            ? <br />
            <br />
            There are a few reasons why this is not so much of a problem in
            practice: First, you'll realize that there are very few labels
            you'll need on a regular basis. Groceries is one of those. The rest
            are either recurring expenses or one off. You will get used to it
            vey fast. Second, if you use the SMS based system, chances are that
            the labels are right in front of you, in the discussion's history.
            If you have a doubt, you can look it up! Third, we design the
            interface to make is as easy as possible to delete and insert new
            items. If you did made a mistake, it will be easy to fix. Finally,
            in the case where you repeatedly made the asme mistake, you can
            always go to the list of labels page and "swap" a label for another.
            All the expenses will be updated accordingly.
          </p> */}
        </TextContainer>
      </div>
    </>
  );
};

Page.propTypes = {};

export default Page;
