import React, { useState } from 'react';

import { generateExpense } from '../../store/expense.utils';

const TutorialStep5 = () => {
  const [example, setExample] = useState({});

  function onChange(e) {
    if (e.target.value) {
      setExample(generateExpense(e.target.value, '1'));
    } else {
      setExample({});
    }
  }

  return (
    <div>
      <h1>Recurring Expenses</h1>
      <p>
        Some expenses or revenues are always the same amount, and happen at
        regular intervals. For example, salaries, internet bills, etc. <br />
        <br />
        Instead of having to enter those evey time, you can tell the system to
        repeat them automatically!
        <br />
        <br />
        Type any expense below as we have seen before:
      </p>
      <input className='w3-input w3-border ' onChange={onChange} />
      {example.original && (
        <p>
          Excellent! Now what if you want to make it a recurring expense? To do
          so, simply add the word <code>rec1m</code>. Type it now.
        </p>
      )}
      {example.original && example.recurrent === 'rec1m' && (
        <p>
          Perfect! This expense will repeat every month. To make it every two
          months, you would type <code>rec</code> (for recurring),{' '}
          <code>2</code> (two) and <code>m</code> (months). Try it out!{' '}
        </p>
      )}
      {example.original && example.recurrent === 'rec2m' && (
        <p>
          Perfect! This expense will repeat every two months. You can use{' '}
          <code>rec3m</code> for three, etc. <br />
          <br /> Click next!
        </p>
      )}
    </div>
  );
};

TutorialStep5.propTypes = {};

export default TutorialStep5;
