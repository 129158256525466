import React, { useState } from 'react';
import { useSnapshot } from 'valtio';

import store from '../store';

import './Login.css';

const Login = () => {
  const [data, setData] = useState({ username: '', pwd: '' });
  const { loading } = useSnapshot(store.auth);

  // called after the form submission
  async function onLogin(event) {
    try {
      event.stopPropagation();
      event.preventDefault();
      if (data.username && data.pwd) {
        await store.auth.login(data.username, data.pwd);
      } else {
        store.alert.logErr('Both fields are required.');
      }
    } catch (error) {
      let message = 'Something went wrong. Please try again.';
      const wrongPassword = error.message.includes('wrong-password');
      const wrongUser = error.message.includes('user-not-found');
      if (wrongPassword || wrongUser) {
        message = 'Your information are incorrect. Did you mistype something?';
      }
      store.alert.logErr(message);
    }
  }

  // called on input changes
  function onFormUpdate(event) {
    const dataName = event.target.getAttribute('name');
    setData({ ...data, [dataName]: event.target.value });
  }
  return (
    <form
      className='w3-border w3-round login-form'
      onSubmit={onLogin}
      style={{ backgroundColor: 'white' }}
    >
      <h2>Login</h2>
      <br />
      <label htmlFor='email-input'>Email</label>
      <br />
      <input
        id='email-input'
        className='w3-input'
        value={data['username']}
        onChange={onFormUpdate}
        type='email'
        name='username'
      />
      <br />
      <label htmlFor='pwd-input'>Password</label>
      <br />
      <input
        id='pwd-input'
        className='w3-input'
        value={data['pwd']}
        onChange={onFormUpdate}
        type='password'
        name='pwd'
      />
      <br />
      <br />
      <button
        className={
          'w3-button w3-gentlefi-action w3-block ' +
          (loading ? 'w3-disabled' : '')
        }
        onClick={onLogin}
      >
        Login
      </button>
    </form>
  );
};

Login.propTypes = {};

export default Login;
