import React, { useState } from 'react';
import dayjs from 'dayjs';

import { generateExpense } from '../../store/expense.utils';

const TutorialStep3 = () => {
  const [example, setExample] = useState({});

  function onChange(e) {
    if (e.target.value) {
      setExample(generateExpense(e.target.value, '1'));
    } else {
      setExample({});
    }
  }
  const dayjsDate = example.original
    ? dayjs(`${example.xpdate}`, 'YYYYMMDD')
    : null;

  return (
    <div>
      <h1>How about a date?</h1>
      <p>Try to type a new expense as seen in the previous step:</p>
      <input className='w3-input w3-border ' onChange={onChange} />
      {example.original && (
        <p>
          Excellent! If you type it like this, the date of this expense will be
          the current date ({dayjs().format('MMM DD')}). <br />
          <br /> If your expense happened on another day, you can specify this
          other date by using the month's three letter abbreviations. For
          example, January 11 2023 would be{' '}
          <b>
            <code>jan11</code>
          </b>
          . Now try to set your expense's date to July 12 2023!
        </p>
      )}
      {example.original && dayjsDate.format('YYYY-MM-DD') === '2023-07-12' && (
        <p>
          Perfect! If you wish to see abbreviations in other languages, let us
          know!
          <br />
          <br /> You can click next.
        </p>
      )}
    </div>
  );
};

TutorialStep3.propTypes = {};

export default TutorialStep3;
