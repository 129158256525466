import React, { useState } from 'react';

import { generateExpense } from '../../store/expense.utils';

const TutorialStep2 = () => {
  const [example, setExample] = useState({});

  function onChange(e) {
    if (e.target.value) {
      setExample(generateExpense(e.target.value, '1'));
    } else {
      setExample({});
    }
  }

  return (
    <div>
      <h1>Entering Expenses / Revenues</h1>
      <p>
        The bare minimum to enter an expense is to enter a number. Try it out
        below, enter any number!
      </p>
      <input className='w3-input w3-border ' onChange={onChange} />
      {example.original && example.isExpense && (
        <p>
          Excellent! You just entered a new expense of ${example.amount}. To
          make it a revenue line, type a "+" in front of the number
        </p>
      )}
      {example.original && !example.isExpense && (
        <p>
          Excellent! You just entered a new revenue of ${example.amount}. <br />
          <br /> You can click next.
        </p>
      )}
    </div>
  );
};

TutorialStep2.propTypes = {};

export default TutorialStep2;
