import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ROUTES } from '../constants';

const EmptyXpenseList = (props) => {
  return (
    <div className='w3-panel'>
      <p>It looks like you have no expenses for this month.</p>
      <p>
        To (re)learn how to enter a line of expense, go to the{' '}
        <Link to={ROUTES.PLAYGROUND}>playground</Link>.
      </p>
      <p>
        When you feel ready, click on the{' '}
        <i
          className='material-icons w3-large w3-button'
          onClick={props.toggleAdd}
        >
          add
        </i>{' '}
        icon above to add a new expense line.
      </p>
    </div>
  );
};

EmptyXpenseList.propTypes = {
  toggleAdd: PropTypes.func,
};

export default EmptyXpenseList;
