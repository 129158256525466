import { proxy } from 'valtio';
import { proxyMap } from 'valtio/utils';
import dayjs from 'dayjs';
import {
  collection,
  query,
  where,
  setDoc,
  getDocs,
  doc,
  deleteDoc,
} from 'firebase/firestore';

import { db, COLLECTIONS, auth } from './firebase';

import { generateExpense } from './expense.utils';

const expenesRef = collection(db, COLLECTIONS.EXPENSES);

const state = proxy({
  loading: false,
  error: '',
  expenses: proxyMap(),
  labels: [],
  fetchMonth: async (monthKey, force = false) => {
    // fetch all the expenses for a specific month
    // month is the month key YYYY-MM
    // force indicates if we should fetch the data even if we have already before
    try {
      if (!state.expenses.get(monthKey) || force) {
        state.loading = true;
        const monthStart = dayjs(monthKey + '-01')
          .startOf('month')
          .format('YYYYMMDD');
        const monthEnd = dayjs(monthKey + '-01')
          .endOf('month')
          .format('YYYYMMDD');

        const q = query(
          expenesRef,
          where('uid', '==', auth?.currentUser?.uid),
          where('xpdate', '>=', parseInt(monthStart)),
          where('xpdate', '<=', parseInt(monthEnd))
        );
        const querySnapshot = await getDocs(q);
        state.expenses.set(monthKey, []);

        querySnapshot.forEach((doc) => {
          const xp = { ...doc.data(), id: doc.id };
          xp.xpdate = dayjs(`${xp.xpdate}`, 'YYYYMMDD');
          state.expenses.get(monthKey).push(xp);
        });
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    } finally {
      state.loading = false;
    }
  },
  add: async (newUserInputs) => {
    // generate the expense object from the original user input and store it in db
    // newUserInputs is an array of strings
    try {
      const uid = auth?.currentUser?.uid;
      if (!uid) {
        throw new Error('user is not authenticated');
      }
      for (let newUserInput of newUserInputs) {
        const newExpense = generateExpense(newUserInput, uid);
        const docRef = doc(expenesRef);
        await setDoc(docRef, newExpense);
        newExpense.id = docRef.id;
        const monthKey = dayjs(`${newExpense.xpdate}`, 'YYYYMMDD').format(
          'YYYY-MM'
        );
        newExpense.xpdate = dayjs(`${newExpense.xpdate}`, 'YYYYMMDD');
        if (!state.expenses.get(monthKey)) {
          state.expenses.set(monthKey, []);
        }
        state.expenses.get(monthKey).push(newExpense);
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
      throw error;
    } finally {
      state.loading = false;
    }
  },
  delete: async (xpense) => {
    // delete the xpense
    // xpense is the expense object to delete
    try {
      await deleteDoc(doc(db, COLLECTIONS.EXPENSES, xpense.id));
      const monthKey = xpense.xpdate.format('YYYY-MM');
      const xpenseList = state.expenses.get(monthKey);
      let xpIndex = xpenseList.findIndex((el) => el.id === xpense.id);
      if (xpIndex !== -1) {
        xpenseList.splice(xpIndex, 1);
      } else {
        throw new Error('Impossible to find xp ' + xpense.id + ' in store');
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
      throw error;
    } finally {
      state.loading = false;
    }
  },
  deleteAll: async (ids, monthKey) => {
    // delete the xpense
    // ids is an array containing the ids of the xpense to delete
    // monthKey is the month the ids belong to formatted as YYYY-MM
    try {
      const xpenseList = state.expenses.get(monthKey);
      for (let id of ids) {
        await deleteDoc(doc(db, COLLECTIONS.EXPENSES, id));
        let xpIndex = xpenseList.findIndex((el) => el.id === id);
        if (xpIndex !== -1) {
          xpenseList.splice(xpIndex, 1);
        } else {
          console.error('Impossible to find xp ' + id + ' in the store');
        }
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
      throw error;
    }
  },
  cleanup: async () => {
    state.expenses = proxyMap();
    state.labels = [];
  },
});

export default state;
