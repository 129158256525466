import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { useSnapshot } from 'valtio';
import { useNavigate } from 'react-router-dom';

import Header from '../component/Header';
import XpenseList from '../component/XpenseList';
import XpenseListToolbar from '../component/XpenseListToolbar';
import AddExpense from '../component/AddExpense';
import AddSeveralExpenses from '../component/AddSeveralExpenses';
import FilterExpense from '../component/FilterExpense';
import TotalSummary from '../component/TotalSummary';
import EmptyXpenseList from '../component/EmptyXpenseList';

import { ROUTES } from '../constants';

import useSwipe from '../hook/useSwipe';

import store from '../store';

function generateXpenseList(fullList, filters) {
  return [...(fullList || [])]
    .filter((xp) => {
      if (filters.length === 0) {
        return true;
      } else {
        for (let f of filters) {
          if (!xp.labels[f]) {
            return false;
          }
        }
        return true;
      }
    })
    .sort((xp1, xp2) => xp2.xpdate - xp1.xpdate);
}

const Home = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState('');
  const [filters, setFilters] = useState([]);
  const [additionalFilter, setAdditionalFilter] = useState('');

  const thisMonth = dayjs().startOf('month');
  const [curDate, setCurDate] = useState(thisMonth);

  const { expenses, loading } = useSnapshot(store.expense);
  const { profile } = useSnapshot(store.user);

  useEffect(() => {
    if (profile.id && !profile.tutoDone) {
      navigate(ROUTES.ONBOARDING);
    }
  }, [profile.id, profile.tutoDone, navigate]);

  useEffect(() => {
    store.expense.fetchMonth(curDate.format('YYYY-MM'));
  }, [curDate]);

  function prevMonth() {
    setCurDate(curDate.subtract(1, 'month'));
  }
  const curDateIsThisMonth =
    curDate.format('YYYY-MM') === thisMonth.format('YYYY-MM');
  function nextMonth() {
    if (!curDateIsThisMonth) {
      setCurDate(curDate.add(1, 'month'));
    }
  }
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    onSwipedLeft: nextMonth,
    onSwipedRight: prevMonth,
    onSwipedUp: () => null,
    onSwipedDown: () => null,
  });
  function toggleAdd() {
    setShow(show === 'add' ? '' : 'add');
  }
  function toggleAddSeveral() {
    setShow(show === 'addseveral' ? '' : 'addseveral');
  }
  function toggleFilter() {
    setFilters([]);
    setAdditionalFilter('');

    setShow(show === 'filter' ? '' : 'filter');
  }

  function onNewFilter(filterInput) {
    if (filterInput === '') {
      setFilters([]);
    } else {
      setFilters(filterInput.trim().toLowerCase().split(' '));
    }
  }

  function refresh() {
    store.expense.fetchMonth(curDate.format('YYYY-MM'), true);
  }

  function onLabelClick(label) {
    if (label !== additionalFilter) {
      setAdditionalFilter(label);
    }
    if (filters.indexOf(label) === -1) {
      setFilters([...filters, label]);
    }
    if (show !== 'filter') {
      setShow('filter');
    }
  }

  const monthKey = curDate.format('YYYY-MM');

  const expensesList = generateXpenseList(expenses.get(monthKey), filters);
  return (
    <>
      <Header />
      <div
        className='gf-page w3-padding'
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        {show === '' && (
          <XpenseListToolbar
            prevMonth={prevMonth}
            nextMonth={nextMonth}
            curDateIsThisMonth={curDateIsThisMonth}
            refresh={refresh}
            toggleAdd={toggleAdd}
            toggleAddSeveral={toggleAddSeveral}
            toggleFilter={toggleFilter}
            curDate={curDate}
          />
        )}
        {show === 'add' && (
          <AddExpense
            onCancel={toggleAdd}
            onSubmit={toggleAdd}
            onAddSeveral={toggleAddSeveral}
          />
        )}
        {show === 'addseveral' && (
          <AddSeveralExpenses
            onCancel={toggleAddSeveral}
            onSubmit={toggleAddSeveral}
          />
        )}
        {show === 'filter' && (
          <FilterExpense
            onCancel={toggleFilter}
            onUserInput={onNewFilter}
            addFilter={additionalFilter}
          />
        )}
        {show !== 'addseveral' && (
          <div style={{ marginTop: 10, marginBottom: 10 }}>
            {loading && 'Loading List...'}
            {!loading && <TotalSummary expenses={expensesList} />}
            {!loading && (
              <XpenseList
                expenses={expensesList}
                monthKey={monthKey}
                onLabelClick={onLabelClick}
              />
            )}
            {!loading && filters.length === 0 && expensesList.length === 0 && (
              <EmptyXpenseList toggleAdd={toggleAdd} />
            )}
            {!loading && filters.length !== 0 && expensesList.length === 0 && (
              <div className='w3-panel'>No expense matches your criteria.</div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

Home.propTypes = {};

export default Home;
