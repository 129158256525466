import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormToSms from '../FormToSms';

import { PHONE_NUMBER } from '../../constants';

const OnboardingStep3 = (props) => {
  const [smsSyntax, setSmsSyntax] = useState('');
  const [done, setDone] = useState(false);

  function toggleDone() {
    setDone(!done);
  }
  function onNewSms(newSms) {
    setSmsSyntax(newSms);
  }
  return (
    <div style={{ paddingBottom: 50 }}>
      <h2>Recurring Expenses</h2>
      Some expenses are recurring. For example, internet bills, or health
      insurance premium. There is an easy way to tell the system about such
      expenses. Please pick a recurring expense of yours, and fill out the form
      as before.
      <FormToSms onFormChange={onNewSms} showRevenue={false} />
      <p>
        When you are satisfied with all the information, send the text below to
        Gentle Fi (
        <b>
          <code>{PHONE_NUMBER}</code>
        </b>
        ): <br />
      </p>
      <div className='w3-bar'>
        <i className='w3-bar-item material-icons'>sms</i>
        <b className='w3-bar-item'>
          <code>
            {smsSyntax === ''
              ? 'Enter some information to see the SMS'
              : smsSyntax}
          </code>
        </b>
      </div>
      {smsSyntax !== '' && !done && (
        <div style={{ marginTop: 30 }}>
          <button onClick={toggleDone} className='w3-button w3-gentlefi-action'>
            Click Here After Sending The Message
          </button>
        </div>
      )}
      {done && (
        <div style={{ marginTop: 30 }}>
          Excellent! You now know how to enter a recurring expense. use the{' '}
          <code>rec</code> keyword, followed by the frequency and the interval.
          <br />
          <ul>
            <li>
              <b>
                <code>rec1d</code>
              </b>
              : Every day
            </li>
            <li>
              <b>
                <code>rec2d</code>
              </b>
              : Every two weeks
            </li>
            <li>
              <b>
                <code>rec3m</code>
              </b>
              : Every three months
            </li>
            <li>
              <b>
                <code>rec4y</code>
              </b>
              : Every four years
            </li>
          </ul>
          Play around with this form to enter more expenses until you feel
          comfortable with the syntax, then click the Next button below.
          <br />
          <br />
          <button
            onClick={props.onNext}
            className='w3-button w3-gentlefi-action'
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

OnboardingStep3.propTypes = {
  onNext: PropTypes.func,
};

export default OnboardingStep3;
