import React, { useState } from 'react';
import PropTypes from 'prop-types';
import store from '../store';

const AddSeeralExpenses = (props) => {
  const [xpenseInput, setXpenseInput] = useState('');
  const [loading, setLoading] = useState(false);

  async function onSubmit(event) {
    try {
      event.stopPropagation();
      event.preventDefault();
      if (xpenseInput) {
        const newInputs = xpenseInput.trim().split(/\r?\n/);
        setLoading(true);
        await store.expense.add(newInputs);
        store.alert.logSuccess('Saved ' + newInputs.length + ' lines');
        setXpenseInput('');
        if (props.onSubmit) {
          props.onSubmit();
        }
      }
    } catch (error) {
      store.alert.logErr('Something went wrong. Please check and try again.');
    } finally {
      setLoading(false);
    }
  }
  function onUserInput(event) {
    setXpenseInput(event.target.value);
  }
  function onCancel() {
    setXpenseInput('');
    if (props.onCancel) {
      props.onCancel();
    }
  }
  return (
    <>
      <div className='w3-bar'>
        <button
          className='w3-button w3-bar-item w3-right w3-bar'
          style={{ paddingTop: 0, paddingBottom: 0 }}
          onClick={onCancel}
        >
          <i className='w3-bar-item material-icons' style={{ paddingRight: 0 }}>
            cancel
          </i>
          <span className='w3-bar-item' style={{ paddingLeft: 10 }}>
            Close
          </span>
        </button>
      </div>
      <p>Write one expense per line</p>
      <form onSubmit={onSubmit}>
        <textarea
          className='w3-input w3-border w3-round'
          style={{ height: 300 }}
          placeholder='Example:
          30.23 groceries 2023-06-01
          50.12 car 2023-07-10'
          onChange={onUserInput}
          value={xpenseInput}
        ></textarea>
        <button
          className={
            'w3-button w3-gentlefi-action w3-round w3-block' +
            (loading ? ' w3-disabled' : '')
          }
          style={{ marginTop: 10 }}
          type='submit'
          onClick={onSubmit}
        >
          Submit Expenses
        </button>
      </form>
    </>
  );
};

AddSeeralExpenses.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default AddSeeralExpenses;
