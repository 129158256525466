import React from 'react';

import Header from '../component/Header';
import AccountForm from '../component/AccountForm';
import PasswordChangeForm from '../component/PasswordChangeForm';

const Page = () => {
  return (
    <>
      <Header />
      <div className='gf-page w3-padding'>
        <AccountForm />
        <br />
        <PasswordChangeForm />
        <br />
      </div>
    </>
  );
};

Page.propTypes = {};

export default Page;
