import { proxy, ref } from 'valtio';
import { updateDoc, getDoc, doc } from 'firebase/firestore';

import { db, COLLECTIONS, auth } from './firebase';

const defaultCurFormatter = new Intl.NumberFormat('en-CA', {
  currency: 'CAD',
  style: 'currency',
});

const state = proxy({
  loading: false,
  error: '',
  profile: {},
  currencyFormatter: ref(defaultCurFormatter),
  fetch: async (force = false) => {
    // fetch the user profile
    // force indicates if we should fetch the data even if we have already before
    try {
      if ((!state.profile.uid || force) && auth?.currentUser?.uid) {
        state.loading = true;
        const profileRef = doc(
          db,
          COLLECTIONS.PROFILES,
          auth?.currentUser?.uid
        );
        const docSnap = await getDoc(profileRef);

        state.profile = { ...docSnap.data(), uid: docSnap.id, id: docSnap.id };
        if (state.profile.locale && state.profile.currencySymbol) {
          state.currencyFormatter = ref(
            new Intl.NumberFormat(state.profile.locale, {
              currency: state.profile.currencySymbol,
              style: 'currency',
            })
          );
        }
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    } finally {
      state.loading = false;
    }
  },
  save: async (newProfile) => {
    // save the user profile
    // newProfile contains any field of the profile that needs updating
    //    it does not have to be a full profile
    try {
      if (auth?.currentUser?.uid) {
        state.loading = true;
        const profileRef = doc(
          db,
          COLLECTIONS.PROFILES,
          auth?.currentUser?.uid
        );
        await updateDoc(profileRef, newProfile);
        for (let field of Object.keys(newProfile)) {
          state.profile[field] = newProfile[field];
        }
        if (
          newProfile.hasOwnProperty('currencySymbol') ||
          newProfile.hasOwnProperty('locale')
        ) {
          state.currencyFormatter = ref(
            new Intl.NumberFormat(state.profile.locale, {
              currency: state.profile.currencySymbol,
              style: 'currency',
            })
          );
        }
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    } finally {
      state.loading = false;
    }
  },
});

export default state;
