import React from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';

import AccountForm from '../AccountForm';

import store from '../../store';

import { PHONE_NUMBER } from '../../constants';

const OnboardingStep1 = (props) => {
  const { admin } = useSnapshot(store.admin);

  return (
    <div>
      <h1>Welcome to Gentle Finance!</h1>
      <p>
        Thank you for using Gentle Finance. Our goal is to make tracking one's
        expenses as easy and hurdle free as possible.
      </p>
      <p>This page will show you how to track your expenses with your phone.</p>
      {!admin.phoneVerified && (
        <div>
          <p>
            In order to start, we have to setup your account. Please fill out
            the form below. A verification code will appear on the screen. You
            will need to send it via text to <b>{PHONE_NUMBER}</b>
          </p>
          <AccountForm />
        </div>
      )}
      {admin.phoneVerified && (
        <div className='w3-panel w3-leftbar w3-pale-green w3-padding w3-border-green'>
          Excellent! Let's track your first expense!
          <div className='w3-bar'>
            <button
              onClick={props.onNext}
              className='w3-bar-item w3-right w3-button w3-gentlefi-action w3-border w3-round'
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

OnboardingStep1.propTypes = {
  onNext: PropTypes.func,
};

export default OnboardingStep1;
