import React from 'react';

import Header from '../component/Header';

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <div className='content w3-padding'>
        <div className='w3-panel w3-leftbar w3-gentlefi-l4 w3-border-gentlefi'>
          <h3>tl;dr</h3>
          <ul>
            <li>We don't sell your data</li>
            <li>Minors can't use this website</li>
            <li>Your data are stored in Montreal, QC, Canada</li>
            <li>
              We collect email and phone number for the use of the website
            </li>
            <li>
              You have the right to change or delete your information and we
              have 30 days to comply{' '}
            </li>
          </ul>
        </div>
        <iframe
          title='Privacy Policy for Gentle Finance'
          className='w3-block'
          style={{ height: '100vh' }}
          src='https://docs.google.com/document/d/e/2PACX-1vTohWGiDUznGati0qLuJxKFYl8iX1LbjKj-JLaO9wS2jVrP3Ze5y9uDsPjnjJmhRA/pub?embedded=true'
        ></iframe>
      </div>
    </>
  );
};

export default PrivacyPolicy;
