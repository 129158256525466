import React, { useState } from 'react';
import PropTypes from 'prop-types';

import store from '../store';

function getSelectedItems(items) {
  return Object.values(items).reduce((acc, curr) => (curr ? acc + 1 : acc), 0);
}

const RecurrentList = (props) => {
  const [selectedItems, setSelectedItems] = useState({});
  const [toggleAll, setToggleAll] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  async function onDelete() {
    try {
      const userChoice = window.confirm('Are you sure?');
      if (userChoice) {
        setDeleteLoading(true);
        const idsToDelete = Object.keys(selectedItems).filter(
          (id) => selectedItems[id]
        );
        await store.rec.delete(idsToDelete);
        store.alert.logSuccess('Deletion complete');
        setSelectedItems({});
        setToggleAll(false);
      }
    } catch (error) {
      console.error(error.message);
      store.alert.logErr('Something went wrong. Please check and try again.');
    } finally {
      setDeleteLoading(false);
    }
  }

  function onSelectItem(id) {
    setSelectedItems({ ...selectedItems, [id]: !selectedItems[id] });
    const nbSelectedItem = getSelectedItems(selectedItems);
    let newToggleAll = nbSelectedItem !== 1 || !selectedItems[id];
    setToggleAll(newToggleAll);
  }
  function onToggleAll() {
    const newSelectedItems = {};
    setToggleAll(!toggleAll);
    setSelectedItems(newSelectedItems);
  }
  return (
    <div>
      <table className='w3-table '>
        <thead>
          {!toggleAll && (
            <tr>
              <th></th>
              <th>Frequency</th>
              <th>Next Occurrence</th>
              <th>Expense line</th>
            </tr>
          )}
          {toggleAll && (
            <tr
              className='w3-gentlefi-l5 w3-border'
              style={{ paddingTop: 0, paddingBottom: 0 }}
            >
              <td>
                <input
                  type='checkbox'
                  onChange={onToggleAll}
                  checked={toggleAll}
                />
              </td>
              <td></td>
              <td>
                <button
                  className={
                    'w3-button w3-round w3-small w3-red ' +
                    (deleteLoading ? 'w3-disabled' : '')
                  }
                  onClick={onDelete}
                >
                  Delete Selection
                </button>
              </td>
            </tr>
          )}
        </thead>

        <tbody>
          {props.list.map((item) => (
            <ListItem
              key={item.id}
              item={item}
              onSelectItem={onSelectItem}
              selected={selectedItems[item.id] || false}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

function createIntervalLabel(rec) {
  let res = 'month';
  if (rec.interval === 'd') {
    res = 'day';
  } else if (rec.interval === 'w') {
    res = 'week';
  } else if (rec.interval === 'y') {
    res = 'year';
  }
  if (rec.frequency === 1) {
    res = 'Every ' + res;
  } else {
    res = 'Every ' + rec.frequency + ' ' + res + 's';
  }

  return res;
}
const ListItem = (props) => {
  function onSelect() {
    props.onSelectItem(props.item.id);
  }

  let intervalLabel = createIntervalLabel(props.item);

  return (
    <tr className=''>
      <td className=''>
        <input
          className=''
          type='checkbox'
          onChange={onSelect}
          checked={props.selected}
        />
      </td>
      <td className=''>{intervalLabel}</td>
      <td className=''>{props.item.nextExecution.format('MMM DD')}</td>
      <td className=''>{props.item.original}</td>
    </tr>
  );
};

RecurrentList.propTypes = {
  list: PropTypes.array, // list of recurring lines
};

export default RecurrentList;
