import React from 'react';
import PropTypes from 'prop-types';

const OnboardingStep5 = (props) => {
  return (
    <div>
      <h1>All Done!</h1>
      <p>That's it! You know how to use Gentle Finance.</p>
      <p>
        By clicking Next, you will be redirected to the dashboard. You will be
        able to see all the expenses and revenues you have entered. We hope
        you'll enjoy using Gentle Finance. Let us know if we can help.{' '}
      </p>
      <div className='w3-bar'>
        <button
          onClick={props.onNext}
          className='w3-bar-item w3-right w3-button w3-gentlefi-action w3-border w3-round'
        >
          Next
        </button>
      </div>
    </div>
  );
};

OnboardingStep5.propTypes = {
  onNext: PropTypes.func,
};

export default OnboardingStep5;
