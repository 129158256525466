import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import LabelsHelpModal from './LabelsHelpModal';

function formDataToSms(formData) {
  const today = dayjs();
  // formatting of the date
  const xpenseDateIsToday = formData?.xpDate === today.format('YYYY-MM-DD');
  const thisYear = today.format('YYYY');
  const xpenseDateIsThisYear = formData?.xpDate?.split('-')[0] === thisYear;
  let smsDate = '';
  if (formData?.xpDate && xpenseDateIsThisYear && !xpenseDateIsToday) {
    smsDate = dayjs(formData?.xpDate, 'YYYY-MM-DD')
      .format('MMMDD')
      .toLowerCase();
  }
  if (formData?.xpDate && !xpenseDateIsThisYear) {
    smsDate = formData.xpDate;
  }

  // formatting of the recurring information
  let smsRec = '';
  if (formData.recurring === 'yes' && formData.recFrequency > 0) {
    smsRec = 'rec' + formData.recFrequency + formData.recInterval[0];
  }

  // formatting of the amount
  let smsAmount = '';
  if (formData.amount) {
    smsAmount = formData.amount.replace(/[^0-9.]/g, '');
    if (formData.revenue === 'yes') {
      smsAmount = '+' + smsAmount;
    }
  }

  return `${smsAmount} ${formData.labels} ${smsDate} ${smsRec}`.trim();
}

const FormToSms = (props) => {
  const today = dayjs();
  const [showHelp, setShowHelp] = useState('none');
  const [formData, setFormData] = useState({
    amount: 0,
    revenue: 'no',
    xpDate: today.format('YYYY-MM-DD'),
    labels: '',
    recurring: 'no',
    recFrequency: 1,
    recInterval: 'monthly',
  });

  useEffect(() => {
    const newSms = formDataToSms(formData);
    props.onFormChange(newSms);
    // eslint-disable-next-line
  }, [formData]);

  function onToggleHelp(event) {
    const toggleCode = event.target.getAttribute('name');
    setShowHelp(toggleCode);
  }

  function onChange(event) {
    const dataToUpdate = event.target.getAttribute('name');
    setFormData({ ...formData, [dataToUpdate]: event.target.value });
  }

  return (
    <form className='w3-border w3-round w3-panel w3-padding w3-light-grey'>
      {showHelp === 'labels' && <LabelsHelpModal onClose={onToggleHelp} />}
      <label>Amount</label>
      <input
        style={{ marginBottom: 10 }}
        className='w3-input w3-border'
        type='text'
        onChange={onChange}
        name='amount'
        placeholder='Ex: 34.12'
        value={formData.amount}
      />
      {props.showRevenue && (
        <>
          Is it a revenue line? <br />
          <input
            className='w3-radio'
            type='radio'
            name='revenue'
            value='no'
            onChange={onChange}
            checked={formData.revenue === 'no'}
          />{' '}
          <label>No</label>{' '}
          <input
            className='w3-radio'
            type='radio'
            name='revenue'
            value='yes'
            onChange={onChange}
            checked={formData.revenue === 'yes'}
          />{' '}
          <label>Yes</label>
          <br></br> <br />
        </>
      )}
      <label>
        <span>Labels</span>{' '}
        <i
          name='labels'
          onClick={onToggleHelp}
          className='material-icons w3-large clickable'
        >
          help_outline
        </i>
      </label>
      <input
        style={{ marginBottom: 10 }}
        className='w3-input w3-border'
        type='text'
        onChange={onChange}
        name='labels'
        placeholder='Ex: groceries cake'
        value={formData.labels}
      />
      <label>Date (YYYY-MM-DD)</label>
      <input
        style={{ marginBottom: 10 }}
        className='w3-input w3-border'
        type='text'
        onChange={onChange}
        name='xpDate'
        value={formData.xpDate}
      />
      {props.showRec && (
        <div>
          Does it Repeat?
          <br />
          <input
            className='w3-radio'
            type='radio'
            name='recurring'
            value='no'
            onChange={onChange}
            checked={formData.recurring === 'no'}
          />{' '}
          <label>No</label>{' '}
          <input
            className='w3-radio'
            type='radio'
            name='recurring'
            value='yes'
            onChange={onChange}
            checked={formData.recurring === 'yes'}
          />{' '}
          <label>Yes</label>
        </div>
      )}
      {formData.recurring === 'no' && (
        <>
          <br />
          <br />
        </>
      )}
      {formData.recurring === 'yes' && (
        <>
          <label>Repeat Every:</label>{' '}
          <input
            style={{ marginBottom: 10, marginTop: 10 }}
            className=''
            type='number'
            onChange={onChange}
            name='recFrequency'
            value={formData.recFrequency}
          />
          <select
            name='recInterval'
            value={formData.recInterval}
            onChange={onChange}
          >
            <option value='daily'>
              Day{formData.recFrequency > 1 ? 's' : ' '}
            </option>
            <option value='weekly'>
              Week{formData.recFrequency > 1 ? 's' : ' '}
            </option>
            <option value='monthly'>
              Month{formData.recFrequency > 1 ? 's' : ' '}
            </option>
            <option value='yearly'>
              Year{formData.recFrequency > 1 ? 's' : ' '}
            </option>
          </select>
        </>
      )}
    </form>
  );
};

FormToSms.propTypes = {
  onFormChange: PropTypes.func,
  showRec: PropTypes.bool, // tells the form if it should display the recurring related inputs
  showRevenue: PropTypes.bool, // tells the form if it should display the recurring related inputs
};
FormToSms.defaultProps = {
  showRec: true,
  showRevenue: true,
  onFormChange: () => {},
};

export default FormToSms;
