import React from 'react';
import PropTypes from 'prop-types';
import { useSnapshot } from 'valtio';

import store from '../store';

function getRatioInfo(expenses) {
  const res = {
    expense: { number: 0, ratio: 0 },
    revenue: { number: 0, ratio: 0 },
    total: { number: 0, ratio: 0 },
  };
  for (let xp of expenses) {
    if (xp.isExpense) {
      res.expense.number += xp.amount;
    } else {
      res.revenue.number += xp.amount;
    }
    res.total.number += xp.amount;
  }
  const refRatio = res.expense.number * -1 + res.revenue.number;
  if (refRatio === 0) {
    res.expense.ratio = 50;
    res.revenue.ratio = 50;
  } else {
    res.expense.ratio = (res.expense.number * -1 * 100) / refRatio;
    res.revenue.ratio = (res.revenue.number * 100) / refRatio;
  }

  return res;
}

const TotalSummary = (props) => {
  const { currencyFormatter } = useSnapshot(store.user);

  const ratios = getRatioInfo(props.expenses);

  return (
    <div className='w3-panel'>
      <b>Total: {currencyFormatter.format(ratios.total.number)}</b>
      <div className='w3-bar'>
        <div
          className='w3-green w3-left w3-center'
          style={{
            width: ratios.revenue.ratio + '%',
            borderTopLeftRadius: 4,
            borderBottomLeftRadius: 4,
          }}
        >
          {currencyFormatter.format(ratios.revenue.number)}
        </div>
        <div
          className='w3-red w3-right w3-center'
          style={{
            width: ratios.expense.ratio + '%',
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
          }}
        >
          {currencyFormatter.format(ratios.expense.number)}
        </div>
      </div>
    </div>
  );
};

TotalSummary.propTypes = {
  expenses: PropTypes.array, // array of expenses
};

export default TotalSummary;
