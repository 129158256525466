import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Header from '../component/Header';
import Step1 from '../component/onboarding/OnboardingStep1';
import Step2 from '../component/onboarding/OnboardingStep2';
import Step3 from '../component/onboarding/OnboardingStep3';
import Step4 from '../component/onboarding/OnboardingStep4';
import Step5 from '../component/onboarding/OnboardingStep5';

import { ROUTES } from '../constants';

import store from '../store';

const NB_STEPS = 5;

const Page = () => {
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function next() {
    if (step === NB_STEPS) {
      setLoading(true);
      await store.user.save({ tutoDone: true });
      navigate(ROUTES.HOME);
    } else {
      setStep(step + 1);
    }
  }
  return (
    <>
      <Header />
      <div className='gf-page w3-padding'>
        <div
          className='w3-light-grey w3-round w3-section'
          style={{ height: 10 }}
        >
          <div
            className='w3-gentlefi-action w3-container w3-round w3-center'
            style={{ width: (step * 100) / NB_STEPS + '%', height: 10 }}
          ></div>
        </div>

        {loading && 'Redirecting...'}
        {step === 1 && <Step1 onNext={next} />}
        {step === 2 && <Step2 onNext={next} />}
        {step === 3 && <Step3 onNext={next} />}
        {step === 4 && <Step4 onNext={next} />}
        {step === 5 && <Step5 onNext={next} />}
      </div>
    </>
  );
};

Page.propTypes = {};

export default Page;
