export const ROUTES = {
  ROOT: '/',
  HOME: '/home',
  ACCOUNT: '/account',
  PLAYGROUND: '/playground',
  PRIVACYPOLICY: '/privacy-policy',
  TOS: '/tos',
  RECURRENT: '/recurrent',
  TUTORIAL: '/tutorial',
  ONBOARDING: '/onboarding',
};

export const PHONE_NUMBER = '+1 647-956-7019';
