import React from 'react';

const TutorialStep1 = () => {
  return (
    <div>
      <h1>Welcome to Gentle Finance's Tutorial!</h1>
      <p>
        Thank you for using Gentle Finance. Our goal is to make tracking one's
        expenses as easy and hurdle free as possible.
      </p>
      <p>
        This tutorial will explain the basics of how to enter your expenses so
        that our system understand. You will be able to access all those
        information again after finishing it.
      </p>
      <p>It should take less than 10 minutes.</p>
    </div>
  );
};

TutorialStep1.propTypes = {};

export default TutorialStep1;
