import React, { useState } from 'react';
import { useSnapshot } from 'valtio';
import { Link, useLocation } from 'react-router-dom';

import './Header.css';
import { ROUTES } from '../constants';

import store from '../store';

const Header = () => {
  const [showSidebar, setShowSideBar] = useState(false);
  const location = useLocation();
  const { isAuth } = useSnapshot(store.auth);

  function toggleSidebar() {
    setShowSideBar(!showSidebar);
  }

  function onLogout() {
    store.auth.logout();
  }

  const isHome =
    location.pathname === ROUTES.HOME || location.pathname === ROUTES.ROOT;
  const isAccount = location.pathname === ROUTES.ACCOUNT;
  const isPlayground = location.pathname === ROUTES.PLAYGROUND;
  const isRecurrent = location.pathname === ROUTES.RECURRENT;

  const basicLargeCss =
    'w3-bar-item w3-button w3-padding-large w3-round header-item w3-hide-small ';
  const basicSmallCss =
    'w3-bar-item w3-button w3-large w3-padding-large w3-round w3-hide-large w3-hide-medium header-item-mobile ';

  return (
    <div
      className='w3-bar w3-border container'
      style={{ backgroundColor: 'white' }}
    >
      <div className='w3-bar-item'>
        <Link to={ROUTES.HOME} style={{ textDecoration: 'none' }}>
          <img
            className='w3-image'
            src='logo192.png'
            alt='Gentle Finance Logo'
            style={{ width: '100%', maxWidth: 50 }}
          />
          Gentle Finance
        </Link>
      </div>
      {isAuth && (
        <Link
          className={basicLargeCss + (isHome ? 'w3-gentlefi' : '')}
          to={ROUTES.HOME}
        >
          Home
        </Link>
      )}

      {isAuth && (
        <Link
          className={basicLargeCss + (isRecurrent ? 'w3-gentlefi' : '')}
          to={ROUTES.RECURRENT}
        >
          Recurring Expenses
        </Link>
      )}
      <Link
        className={basicLargeCss + (isPlayground ? 'w3-gentlefi' : '')}
        to={ROUTES.PLAYGROUND}
      >
        Playground
      </Link>
      {isAuth && (
        <button className={basicLargeCss + 'w3-right'} onClick={onLogout}>
          Log out
        </button>
      )}
      {isAuth && (
        <Link
          className={basicLargeCss + (isAccount ? 'w3-gentlefi' : '')}
          to={ROUTES.ACCOUNT}
        >
          Account
        </Link>
      )}

      {/* Hamburger Icon */}
      {isAuth && (
        <div className={basicSmallCss + 'w3-right'} onClick={toggleSidebar}>
          <i className='material-icons'>{showSidebar ? 'close' : 'menu'}</i>
        </div>
      )}

      {/* Side Menu */}
      {showSidebar && (
        <SideMenu
          basicSmallCss={basicSmallCss}
          isHome={isHome}
          isAccount={isAccount}
          isPlayground={isPlayground}
          isRecurrent={isRecurrent}
          onLogout={onLogout}
          isAuth={isAuth}
        />
      )}
    </div>
  );
};

const SideMenu = (props) => {
  return (
    <div
      className='w3-sidebar w3-bar-block w3-animate-left'
      style={{ width: '65%' }}
    >
      {props.isAuth && (
        <Link
          className={props.basicSmallCss + (props.isHome ? 'w3-gentlefi' : '')}
          to={ROUTES.HOME}
        >
          Home
        </Link>
      )}
      {props.isAuth && (
        <Link
          className={
            props.basicSmallCss + (props.isRecurrent ? 'w3-gentlefi' : '')
          }
          to={ROUTES.RECURRENT}
        >
          Recurring Expenses
        </Link>
      )}
      <Link
        className={
          props.basicSmallCss + (props.isPlayground ? 'w3-gentlefi' : '')
        }
        to={ROUTES.PLAYGROUND}
      >
        Playground
      </Link>
      {props.isAuth && (
        <Link
          className={
            props.basicSmallCss + (props.isAccount ? 'w3-gentlefi' : '')
          }
          to={ROUTES.ACCOUNT}
        >
          Account
        </Link>
      )}
      {props.isAuth && (
        <button
          className={props.basicSmallCss + 'w3-right'}
          onClick={props.onLogout}
        >
          Log out
        </button>
      )}
    </div>
  );
};

Header.propTypes = {};

export default Header;
