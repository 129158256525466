import { proxy } from 'valtio';
import dayjs from 'dayjs';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
} from 'firebase/firestore';

import { db, COLLECTIONS, auth } from './firebase';

const recurrentRef = collection(db, COLLECTIONS.RECURRENT);

const state = proxy({
  loading: false,
  error: '',
  list: [],
  fetch: async (force = false) => {
    // fetch all the recurring lines of expense for the user
    // force indicates if we should fetch the data even if we have already before
    try {
      if (state.list.length === 0 || force) {
        state.loading = true;

        const q = query(
          recurrentRef,
          where('uid', '==', auth?.currentUser?.uid)
        );
        const querySnapshot = await getDocs(q);
        state.list.splice(0, state.list.length);

        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), id: doc.id };
          data.nextExecution = dayjs(`${data.nextExecution}`, 'YYYYMMDD');
          state.list.push(data);
        });
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
    } finally {
      state.loading = false;
    }
  },
  delete: async (ids) => {
    // delete the recurring lines
    // ids is an array containing the ids of the lines to delete
    try {
      for (let id of ids) {
        await deleteDoc(doc(db, COLLECTIONS.RECURRENT, id));
        let indexToDelete = state.list.findIndex((el) => el.id === id);
        if (indexToDelete !== -1) {
          state.list.splice(indexToDelete, 1);
        } else {
          console.error('Impossible to find recurrent ' + id + ' in the store');
        }
      }
    } catch (error) {
      console.error(error.message);
      state.error = error.message;
      throw error;
    }
  },
  cleanup: async () => {
    state.list = [];
  },
});

export default state;
