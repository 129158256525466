import React from 'react';
import { useSnapshot } from 'valtio';

import store from '../store';

const PasswordChangeForm = () => {
  const { loading } = useSnapshot(store.auth);

  async function onSubmit(event) {
    try {
      event.preventDefault();
      event.stopPropagation();
      const curPwd = document.getElementById('current-pwd').value.trim();
      const newPwd = document.getElementById('new-pwd').value.trim();
      if (!curPwd || !newPwd) {
        throw new Error('Please enter both your current and new password.');
      }
      if (curPwd === newPwd) {
        throw new Error('Your new password cannot be the current one.');
      }
      if (newPwd.length < 8) {
        throw new Error('Your new password must be at least 8 characters');
      }
      await store.auth.changePwd(curPwd, newPwd);
      store.alert.logSuccess('Password Successfully Changed');
    } catch (error) {
      store.alert.logErr(error.message);
    }
  }

  return (
    <>
      <h3>Change Your Password:</h3>
      <form
        onSubmit={onSubmit}
        className='w3-border w3-padding w3-round w3-panel'
      >
        <label>Enter your current password:</label>
        <input id='current-pwd' type='password' className='w3-input' />
        <br />
        <label>Enter your new password (min. 8 characters):</label>
        <input id='new-pwd' type='password' className='w3-input' />
        <br />
        <input
          type='submit'
          className={
            'w3-button w3-gentlefi-action ' + (loading ? 'w3-disabled' : '')
          }
          value='Validate'
          text='Validate'
        />
      </form>
    </>
  );
};

PasswordChangeForm.propTypes = {};

export default PasswordChangeForm;
