import React from 'react';

const TutorialStep6 = () => {
  return (
    <div>
      <h1>All Done!</h1>
      <p>
        That's it! You can now talk to the system. It's the same on the website
        and via SMS/Whatsapp.
      </p>
      <p>
        If you have a doubt or forget, you can find all those information and a
        demo field in the playground page.
      </p>
      <p>
        By clicking Done, you will be redirected to the home page, where you can
        add your first expense. Enjoy!
      </p>
    </div>
  );
};

TutorialStep6.propTypes = {};

export default TutorialStep6;
