import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const FilterExpense = (props) => {
  const [filterInput, setFilterInput] = useState('');

  useEffect(() => {
    if (props.addFilter) {
      setFilterInput((curFilter) => {
        if (curFilter.includes(props.addFilter)) {
          return curFilter;
        } else {
          return curFilter
            ? curFilter + ' ' + props.addFilter
            : props.addFilter;
        }
      });
    }
  }, [props.addFilter]);

  function onSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    if (props.onUserInput) {
      props.onUserInput(filterInput);
    }
  }
  function onUserInput(event) {
    setFilterInput(event.target.value);
  }
  function onCancel() {
    setFilterInput('');
    if (props.onCancel) {
      props.onCancel();
    }
  }
  return (
    <form onSubmit={onSubmit} className='w3-bar'>
      <input
        className='w3-bar-item w3-input w3-border w3-round'
        tpye='text'
        placeholder='ex: groceries'
        onChange={onUserInput}
        value={filterInput}
      />
      <button
        className='w3-button w3-bar-item material-icons w3-green w3-round'
        style={{ marginLeft: 10 }}
        onClick={onSubmit}
      >
        check
      </button>
      <button
        className='w3-hide-large w3-hide-medium w3-button w3-bar-item material-icons w3-red w3-round'
        style={{ marginLeft: 10 }}
        onClick={onCancel}
      >
        close
      </button>
      <button
        className='w3-hide-small w3-button w3-bar-item w3-right w3-bar'
        style={{ paddingTop: 0, paddingBottom: 0 }}
        onClick={onCancel}
      >
        <i className='w3-bar-item material-icons' style={{ paddingRight: 0 }}>
          cancel
        </i>
        <span className='w3-bar-item' style={{ paddingLeft: 10 }}>
          Close Filters
        </span>
      </button>
    </form>
  );
};

FilterExpense.propTypes = {
  onCancel: PropTypes.func,
  onUserInput: PropTypes.func,
  addFilter: PropTypes.string,
};

export default FilterExpense;
