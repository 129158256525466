import React, { useState, useEffect } from 'react';
import { useSnapshot } from 'valtio';

import store from '../store';

import timezones from '../asset/timezone.json';
import currencies from '../asset/currency.json';
import locales from '../asset/locales.json';

import TimezoneHelpModal from './TimezoneHelpModal';
import CurrencyHelpModal from './CurrencyHelpModal';
import LocaleHelpModal from './LocaleHelpModal';
import PhoneHelpModal from './PhoneHelpModal';
import WaNumberHelpModal from './WaNumberHelpModal';

import { PHONE_NUMBER } from '../constants';

const timezoneSelect = timezones.map((t) => ({
  value: t.timezone,
  label: `${t.timezone} - ${t.utc_offset} - ${t.name}`,
}));
timezoneSelect.splice(0, 0, { value: null, label: 'Pick your Timezone' });

const currencySelect = Object.keys(currencies)
  .sort()
  .map((curCode) => ({
    value: curCode,
    label: `${curCode} - ${currencies[curCode]}`,
  }));
currencySelect.splice(0, 0, { value: null, label: 'Pick your Currency' });

const localeSelect = Object.keys(locales)
  .sort()
  .map((code) => ({
    value: code,
    label: `${code} - ${locales[code]}`,
  }));
localeSelect.splice(0, 0, { value: null, label: 'Pick your Region' });

const AccountForm = () => {
  const { profile, loading } = useSnapshot(store.user);
  const { admin } = useSnapshot(store.admin);
  const [showHelp, setShowHelp] = useState('none');
  const [formData, setFormData] = useState({
    phone: '',
    currencySymbol: '',
    timezone: '',
    lcoale: '',
    waNumber: '',
  });
  const [formError, setFormError] = useState(null);
  const [isPristine, setIsPristine] = useState(true);

  useEffect(() => {
    if (profile.uid) {
      setFormData({
        phone: profile.phone,
        currencySymbol: profile.currencySymbol,
        timezone: profile.timezone,
        locale: profile.locale,
        waNumber: profile.waNumber,
      });
    }
  }, [profile]);

  function onChange(event) {
    setIsPristine(false);
    const fieldName = event.target.getAttribute('name');
    setFormData({ ...formData, [fieldName]: event.target.value });
  }

  async function onSubmit(event) {
    try {
      event.preventDefault();
      event.stopPropagation();
      if (!isPristine) {
        if (formData.phone && !formData.phone.startsWith('+')) {
          throw new Error('Your phone number has the wrong format.');
        } else if (formData.waNumber && !formData.waNumber.startsWith('+')) {
          throw new Error('Your whatsapp number has the wrong format.');
        } else {
          setFormError(null);
          await store.user.save(formData);
          store.alert.logSuccess('Profile Saved');
        }
      }
    } catch (error) {
      setFormError(error.message);
    }
  }

  function onToggleHelp(event) {
    const toggleCode = event.target.getAttribute('name');
    setShowHelp(toggleCode);
  }

  return (
    <>
      <h3>Account Information</h3>
      {formError && (
        <div className='w3-panel w3-padding w3-leftbar w3-pale-red w3-border-red'>
          {formError}
        </div>
      )}
      {showHelp === 'timezone' && <TimezoneHelpModal onClose={onToggleHelp} />}
      {showHelp === 'currency' && <CurrencyHelpModal onClose={onToggleHelp} />}
      {showHelp === 'locale' && <LocaleHelpModal onClose={onToggleHelp} />}
      {showHelp === 'phone' && <PhoneHelpModal onClose={onToggleHelp} />}
      {showHelp === 'wa-number' && <WaNumberHelpModal onClose={onToggleHelp} />}
      <form
        onSubmit={onSubmit}
        className='w3-border w3-container w3-padding-16 w3-round'
      >
        <label>
          Timezone{' '}
          <i
            name='timezone'
            onClick={onToggleHelp}
            className='material-icons w3-large clickable'
          >
            help_outline
          </i>
        </label>
        <select
          className='w3-select'
          name='timezone'
          value={formData.timezone}
          onChange={onChange}
        >
          {timezoneSelect.map((t) => (
            <option key={t.value} value={t.value}>
              {t.label}
            </option>
          ))}
        </select>
        <br />
        <br />
        <label>
          Currency Symbol{' '}
          <i
            name='currency'
            onClick={onToggleHelp}
            className='material-icons w3-large clickable'
          >
            help_outline
          </i>
        </label>
        <select
          className='w3-select'
          name='currencySymbol'
          value={formData.currencySymbol}
          onChange={onChange}
        >
          {currencySelect.map((c) => (
            <option key={c.value} value={c.value}>
              {c.label}
            </option>
          ))}
        </select>
        <br />
        <br />
        <label>
          Locale{' '}
          <i
            name='locale'
            onClick={onToggleHelp}
            className='material-icons w3-large clickable'
          >
            help_outline
          </i>
        </label>
        <select
          className='w3-select'
          name='locale'
          value={formData.locale}
          onChange={onChange}
        >
          {localeSelect.map((l) => (
            <option key={l.value} value={l.value}>
              {l.label}
            </option>
          ))}
        </select>
        <br />
        <br />
        <label>
          Phone Number{' '}
          <i
            name='phone'
            onClick={onToggleHelp}
            className='material-icons w3-large clickable'
          >
            help_outline
          </i>
        </label>
        <input
          className='w3-input'
          value={formData.phone}
          name='phone'
          type='tel'
          onChange={onChange}
          placeholder='+16471111111'
          pattern='\+[0-9]{5,15}'
        />
        {!admin.phoneVerified && admin.phone && admin.verificationCode && (
          <div className='w3-panel w3-padding w3-leftbar w3-pale-blue w3-border-blue'>
            Your verification code is {admin.verificationCode}. Please send it
            to{' '}
            <b>
              <code>{PHONE_NUMBER}</code>
            </b>{' '}
            via WhatsApp
            {admin.phone.startsWith('+1') ? ' or SMS.' : '.'}
          </div>
        )}
        {admin.phoneVerified && (
          <div className='w3-panel w3-padding w3-leftbar w3-pale-green w3-border-green'>
            Your phone number is verified
          </div>
        )}
        <br />
        {/* <label>
          WhatsApp Number{' '}
          <i
            name='wa-number'
            onClick={onToggleHelp}
            className='material-icons w3-large clickable'
          >
            help_outline
          </i>
        </label>
        <input
          className='w3-input'
          value={formData.waNumber}
          name='waNumber'
          onChange={onChange}
        />
        <br /> */}
        <button
          className={
            'w3-button w3-gentlefi-action ' +
            (loading || isPristine ? 'w3-disabled' : '')
          }
        >
          Save information
        </button>
      </form>
    </>
  );
};

AccountForm.propTypes = {};

export default AccountForm;
