import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormToSms from '../FormToSms';

import { PHONE_NUMBER } from '../../constants';

const OnboardingStep2 = (props) => {
  const [smsSyntax, setSmsSyntax] = useState('');
  const [done, setDone] = useState(false);

  function toggleDone() {
    setDone(!done);
  }
  function onNewSms(newSms) {
    setSmsSyntax(newSms);
  }
  return (
    <div style={{ paddingBottom: 50 }}>
      <h2>The basics</h2>
      Think of your most recent expense this month. Fill in the form below, and
      it will show you the SMS to send in order to track it in our system.
      <FormToSms onFormChange={onNewSms} showRec={false} showRevenue={false} />
      <p>
        When you are satisfied with all the information, send the text below to
        Gentle Fi (
        <b>
          <code>{PHONE_NUMBER}</code>
        </b>
        ): <br />
      </p>
      <div className='w3-bar'>
        <i className='w3-bar-item material-icons'>sms</i>
        <b className='w3-bar-item'>
          <code>
            {smsSyntax === ''
              ? 'Enter some information to see the SMS'
              : smsSyntax}
          </code>
        </b>
      </div>
      <br />
      {smsSyntax !== '' && (
        <>
          Note that the words' order is irrelevant, so this SMS works as well.
          Just write what feels best!
          <div className='w3-bar'>
            <i className='w3-bar-item material-icons'>sms</i>
            <b className='w3-bar-item'>
              <code>{smsSyntax.split(' ').reverse().join(' ')}</code>
            </b>
          </div>
        </>
      )}
      {smsSyntax !== '' && !done && (
        <div style={{ marginTop: 30 }}>
          <button onClick={toggleDone} className='w3-button w3-gentlefi-action'>
            Click Here After Sending The Message
          </button>
        </div>
      )}
      {done && (
        <div style={{ marginTop: 30 }}>
          Excellent! This is the basic syntax. The amount is simply a number (no
          commas), the date of the expense can be written as <code>jul23</code>,
          and the labels are words that help you track and filter your expenses.{' '}
          <br />
          <br />
          Play around with this form to enter more expenses until you feel
          comfortable with the syntax, then click the Next button below.
          <br />
          <br />
          <button
            onClick={props.onNext}
            className='w3-button w3-gentlefi-action'
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

OnboardingStep2.propTypes = {
  onNext: PropTypes.func,
};

export default OnboardingStep2;
