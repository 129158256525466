import React from 'react';
import PropTypes from 'prop-types';

const WaNumberHelpModal = (props) => {
  return (
    <div className='w3-modal w3-block' name='none' onClick={props.onClose}>
      <div className='w3-modal-content'>
        <div className='w3-container'>
          <span
            name='none'
            onClick={props.onClose}
            className='w3-button w3-display-topright'
          >
            &times;
          </span>
          <h1>WhatsApp number</h1>
          <p>
            This phone number is used to save your expenses when you send them
            via WhatsApp. It has to start with a "+", followed by the country
            code, and only contains number.
            <br />
            <br />
            It's called the{' '}
            <a
              href='https://www.twilio.com/docs/glossary/what-e164'
              target='_blank'
              rel='noopener noreferrer'
              title='New tab toward the E.164 phone number foramt on the website of Twilio.'
            >
              E.164 format{' '}
              <i className='material-icons w3-small'>open_in_new</i>
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

WaNumberHelpModal.propTypes = {
  onClose: PropTypes.func,
};

export default WaNumberHelpModal;
