import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormToSms from '../FormToSms';

import { PHONE_NUMBER } from '../../constants';

const OnboardingStep4 = (props) => {
  const [smsSyntax, setSmsSyntax] = useState('');
  const [done, setDone] = useState(false);

  function toggleDone() {
    setDone(!done);
  }
  function onNewSms(newSms) {
    setSmsSyntax(newSms);
  }
  return (
    <div style={{ paddingBottom: 50 }}>
      <h2>Revenues</h2>
      You can track your revenues just as easily. As you will see below, simply
      add a "+" sign in front of the amount.
      <FormToSms onFormChange={onNewSms} />
      <p>
        When you are satisfied with all the information, send the text below to
        Gentle Fi (
        <b>
          <code>{PHONE_NUMBER}</code>
        </b>
        ): <br />
      </p>
      <div className='w3-bar'>
        <i className='w3-bar-item material-icons'>sms</i>
        <b className='w3-bar-item'>
          <code>
            {smsSyntax === ''
              ? 'Enter some information to see the SMS'
              : smsSyntax}
          </code>
        </b>
      </div>
      {smsSyntax !== '' && !done && (
        <div style={{ marginTop: 30 }}>
          <button onClick={toggleDone} className='w3-button w3-gentlefi-action'>
            Click Here After Sending The Message
          </button>
        </div>
      )}
      {done && (
        <div style={{ marginTop: 30 }}>
          Excellent! This part was easy.
          <br />
          <br />
          Feel free to add more revenue lines if you want to, then click Next.
          <br />
          <br />
          <button
            onClick={props.onNext}
            className='w3-button w3-gentlefi-action'
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

OnboardingStep4.propTypes = {
  onNext: PropTypes.func,
};

export default OnboardingStep4;
