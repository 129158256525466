import React, { useState } from 'react';

import { generateExpense } from '../../store/expense.utils';

const Tag = (props) => (
  <span className='w3-tag w3-round' style={{ marginRight: 5 }}>
    {props.children}
  </span>
);

const TutorialStep4 = () => {
  const [example, setExample] = useState({});
  const [done, setDone] = useState(false);

  function onChange(e) {
    if (e.target.value) {
      if (e.target.value === '50 groceries cake') {
        setDone(true);
      }
      setExample(generateExpense(e.target.value, '1'));
    } else {
      setExample({});
    }
  }

  return (
    <div>
      <h1>Introducing Labels</h1>
      <p>
        Labels are information you want to remember about a specific
        expense/revenue.
      </p>
      <p>
        Type: "<code>50 groceries cake</code>" in the input below
      </p>
      <input className='w3-input w3-border ' onChange={onChange} />
      {example.original === '50 groceries cake' && (
        <div>
          <p>
            Here your expense will be saved with the labels <Tag>groceries</Tag>{' '}
            and <Tag>cake</Tag>. That's how they will appear in your list, and
            later on you can search / filter your expenses using those labels.
          </p>
          <p>
            Careful! Labels cannot have spaces in them. Otherwise you can enter
            anything you want.
          </p>
          <p>
            Try to change the labels as you would with real expenses. For
            example, "car", "restaurant", etc.
          </p>
        </div>
      )}
      {example.original &&
        example.original !== '50 groceries cake' &&
        done === true && (
          <div>
            <p>
              The labels are:{' '}
              {Object.keys(example.labels).map((l) => (
                <Tag key={l}>{l}</Tag>
              ))}
            </p>
            <p>Click next when you are ready.</p>
          </div>
        )}
    </div>
  );
};

TutorialStep4.propTypes = {};

export default TutorialStep4;
