import React, { useEffect } from 'react';
import { useSnapshot } from 'valtio';

import Header from '../component/Header';
import RecurrentList from '../component/RecurrentList';

import store from '../store';

const Page = () => {
  const { list, loading } = useSnapshot(store.rec);
  useEffect(() => {
    store.rec.fetch();
  }, []);

  function onRefresh() {
    store.rec.fetch(true);
  }

  return (
    <>
      <Header />
      <div className='gf-page w3-padding'>
        <div className='w3-bar'>
          <button
            title='refresh list'
            className={
              'w3-bar-item  w3-button ' + (loading ? 'w3-disabled' : '')
            }
            onClick={onRefresh}
          >
            <i className='material-icons w3-medium'>refresh</i> Refresh List
          </button>
        </div>
        {loading && 'Loading List...'}
        {!loading && list.length === 0 && 'No recurring line created'}
        {!loading && list.length > 0 && <RecurrentList list={list} />}
      </div>
    </>
  );
};

Page.propTypes = {};

export default Page;
