import React from 'react';
import PropTypes from 'prop-types';

const LabelsHelpModal = (props) => {
  return (
    <div className='w3-modal w3-block' name='none' onClick={props.onClose}>
      <div className='w3-modal-content'>
        <div className='w3-container'>
          <span
            name='none'
            onClick={props.onClose}
            className='w3-button w3-display-topright'
          >
            &times;
          </span>
          <h1>Labels</h1>
          <p>
            Labels are probably the <b>most powerful feature </b> of Gentle
            Finance. Think of them as tags you can use to group and categorize
            your expenses. Unlike set categories, each expense can have several
            labels attached to them. <br />
            <br />
            For example, what if you spend $50 at a grocery store to buy a cake
            as a gift for someone? With set categories, you would have to pick
            one. Thanks to labels, you can keep track of both information. You
            can even add the name of who you bought this amazing cake for, and
            you'll see it in the web interface later as a reminder!
            <br />
            <br />
            Ex: groceries cake
          </p>
        </div>
      </div>
    </div>
  );
};

LabelsHelpModal.propTypes = {
  onClose: PropTypes.func,
};

export default LabelsHelpModal;
